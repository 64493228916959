import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ArticleInterface, Tag, TagInterface} from "../drupal/entities";
import {ArticleResult, TagResult} from "../drupal/resultTypes";
import {Drupal7Service} from "../drupal/drupal7.service";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {EntityMetaService} from "../drupal/entity-meta.service";
import {tap} from "rxjs";
import {Drupal7mappingService} from "../drupal/drupal7mapping.service";
import {AdPositionName} from "../ads/ad-position/ad-position-map";
import {AdServeServiceService} from "../ads/ad-serve/ad-serve-service.service";
import {isPlatformBrowser} from "@angular/common";
import {SsspService} from "../seznam/sssp.service";

@Component({
  selector: 'app-tag-detail',
  templateUrl: './tag-detail.component.html',
  styleUrls: ['./tag-detail.component.css']
})

export class TagDetailComponent implements AfterViewInit {
  tag: TagInterface | undefined;
  articles: Array<ArticleInterface> | undefined;
  titlePath: string | null = null;
  protected readonly AdPositionName = AdPositionName;

  private readonly isBrowser: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private entityMeta: EntityMetaService,
    private drupal7: Drupal7Service,
    private drupal7mapping: Drupal7mappingService,
    private adServe: AdServeServiceService,
    private sssp: SsspService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit() {
    this.loadContent();

    if (this.isBrowser) {
      this.sssp.setPageViewId();

      this.adServe.serveAds([
        AdPositionName.leaderboard,
        AdPositionName.square,
        AdPositionName.stickySquare,
        AdPositionName.footerboard,
      ]);
    }
  }

  loadMore(){
    const articlesCount = this.articles ? this.articles.length : 0;
    this.drupal7.getViewArticles({viewName: 'taxonomy_term_rubrika', displayId: 'services_1', args: [this.tag?.id], offset: articlesCount}).subscribe((data: Array<ArticleResult>) => this.mapMoreArticles(data));
  }

  private loadContent() {
    this.route.paramMap.pipe(
      tap(params => {
        this.titlePath = params.get('titlepath');
        this.drupal7.getTaxonomyTerm(this.titlePath).pipe(
          tap((data: TagResult) => {
            this.tag = this.drupal7mapping.mapTag(data);
            this.articles = [];
            this.entityMeta.setMeta(new Tag(this.tag));
          }),
          tap(data => {
            //this.drupal7.getViewArticles({viewName: 'taxonomy_term_rubrika', displayId: 'services_1', args: [this.tag?.id]}).subscribe((data: Array<ArticleResult>) => this.mapArticles(data));
          })
        ).subscribe(params => {})
      })
    ).subscribe(params => {})
  }

  private mapArticles(data: Array<ArticleResult>) {
    this.articles = [];
    Object.entries(data).forEach(([key, articleResult]) => {
      this.articles = this.drupal7mapping.mapArticles(data);
    });
  }

  private mapMoreArticles(data: Array<ArticleResult>) {
    this.drupal7mapping.mapArticles(data).forEach((article) => {
      // @ts-ignore
      this.articles.push(article);
    });
  }
}
