<div class="max-w-[1060px] mx-auto">
  <div class="block md:flex p-6 md:space-x-12">
    <div class="w-full md:w-2/3 md:flex-1">
      <ng-template [ngIf]="user" [ngIfElse]="loader">
        <h1 class="text-3xl text-orange-800 font-bold mb-6">{{user.name}}</h1>
        <div class="grid grid-flow-col space-x-4">
          <ng-template [ngIf]="user.phone">
            <div class="mb-4 bg-white p-4 rounded-2xl text-sm"><strong>Telefon:</strong> {{user.phone}}</div>
          </ng-template>
          <ng-template [ngIf]="user.twitter">
            <div class="mb-4 bg-white p-4 rounded-2xl text-sm"><strong>Twitter:</strong> {{user.twitter}}</div>
          </ng-template>
        </div>
        <ng-template [ngIf]="user.description">
          <div class="author-description" itemprop="description" [innerHTML]="user.description"></div>
        </ng-template>
        <app-paging [viewName]="'author_articles'" [displayId]="'services_1'" [args]="[user.id]"></app-paging>
      </ng-template>
      <ng-template #loader>
        <div class="loader"><la-icon class="animate-spin" icon="spinner" size="2x"></la-icon></div>
      </ng-template>
    </div>
    <div class="w-full md:w-1/3 md:max-w-[300px] flex items-stretch">
      <app-sidebar class="relativ w-full"></app-sidebar>
    </div>
  </div>
</div>
