<ng-template [ngIf]="articles" [ngIfElse]="loader">
  <div *ngFor="let article of articles" class="mb-6">
    <app-article-teaser [article]="article"></app-article-teaser>
  </div>
</ng-template>
<ng-template #loader>
  <div class="loader"><la-icon class="animate-spin" icon="spinner" size="2x"></la-icon></div>
</ng-template>

<nav aria-label="Page navigation">
  <ul class="paging">
    <ng-template [ngIf]="currentPage > 1">
      <!-- Previous Page Link -->
      <li class="page-item">
        <a
          class="page-link"
          [routerLink]="[]"
          [queryParams]="{ page: currentPage - 1 }"
          [queryParamsHandling]="'merge'"
          [attr.href]="getHref(currentPage - 1)"
          aria-label="Předchozí">
          &lt;
        </a>
      </li>
    </ng-template>

    <!-- Page Number Links -->
    <li *ngFor="let page of pageNumbers" class="page-item" [class.active]="page === currentPage">
      <a
        class="page-link"
        [routerLink]="[]"
        [queryParams]="{ page: page }"
        [queryParamsHandling]="'merge'"
        [attr.href]="getHref(page)"
      >{{ page }}</a>
    </li>

    <!-- Next Page Link -->
    <li class="page-item" [class.disabled]="currentPage === totalPages">
      <a
        class="page-link"
        [routerLink]="[]"
        [queryParams]="{ page: currentPage + 1 }"
        [queryParamsHandling]="'merge'"
        [attr.href]="getHref(currentPage + 1)"
        [attr.aria-disabled]="currentPage === totalPages ? 'true' : null"
        aria-label="Další">
        &gt;
      </a>
    </li>
  </ul>
</nav>
