import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router, UrlTree} from '@angular/router';
import {ArticleResult} from "../drupal/resultTypes";
import {Drupal7Service} from "../drupal/drupal7.service";
import {Drupal7mappingService} from "../drupal/drupal7mapping.service";
import {AdPositionName} from "../ads/ad-position/ad-position-map";

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.css']
})
export class PagingComponent implements OnInit {
  @Input() viewName: string = '';
  @Input() displayId: string = '';
  @Input() args: any[] = [];
  articles: any[] = []; // Articles to display on the current page
  allArticles: any[] = []; // All articles available
  currentPage: number = 1;
  totalPages: number = 100;
  pageSize: number = 10;
  pageNumbers: number[] = [];

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private drupal7: Drupal7Service,
    private drupal7mapping: Drupal7mappingService
  ) { }

  ngOnInit(): void {
    // Subscribe to route query parameters to get the current page
    this.route.queryParams.subscribe(params => {
      this.currentPage = +params['page'] || 1;
      //this.totalPages = Math.ceil(this.allArticles.length / this.pageSize);
      this.loadPage(this.currentPage);
      this.generatePageNumbers();
    });
  }

  // Load articles for the current page
  loadPage(page: number): void {
    const startIndex = (page - 1) * this.pageSize;
    if (this.viewName && this.displayId) {
      this.drupal7.getViewArticles({
        viewName: this.viewName,
        displayId: this.displayId,
        args: this.args,
        offset: startIndex
      }).subscribe((data: Array<ArticleResult>) => this.mapArticles(data));
    }
  }

  // Generate page numbers to display in the pagination control
  generatePageNumbers(): void {
    this.pageNumbers = [];
    for (let i = this.currentPage - 2; i <= this.currentPage + 2; i++) {
      if (i > 0 && i <= this.totalPages) {
        this.pageNumbers.push(i);
      }
    }
  }

  buildPageLink(page: number): UrlTree {
    // Clone the existing query params and set the new 'page' parameter
    const queryParams = { ...this.route.snapshot.queryParams, page: page };

    // Return a UrlTree object
    return this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: queryParams,
    });
  }

  getHref(page: number): string {
    const urlTree = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: { page: page },
      queryParamsHandling: 'merge',
    });
    return this.router.serializeUrl(urlTree);
  }

  private mapArticles(data: Array<ArticleResult>) {
    this.articles = [];
    Object.entries(data).forEach(([key, articleResult]) => {
      this.articles = this.drupal7mapping.mapArticles(data);
    });
    if (Object.entries(data).length < 10) {
      this.totalPages = this.currentPage;
    }
  }
}
