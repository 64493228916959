import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {ArticleInterface, Section, SectionInterface, User, UserInterface} from "../drupal/entities";
import {ActivatedRoute, Router} from "@angular/router";
import {EntityMetaService} from "../drupal/entity-meta.service";
import {Drupal7Service} from "../drupal/drupal7.service";
import {Drupal7mappingService} from "../drupal/drupal7mapping.service";
import {AdServeServiceService} from "../ads/ad-serve/ad-serve-service.service";
import {SsspService} from "../seznam/sssp.service";
import {isPlatformBrowser} from "@angular/common";
import {tap} from "rxjs";
import {TaxonomyTermResult, UserResult} from "../drupal/resultTypes";
import {AdPositionName} from "../ads/ad-position/ad-position-map";

@Component({
  selector: 'app-author-detail',
  templateUrl: './author-detail.component.html',
  styleUrls: ['./author-detail.component.css']
})
export class AuthorDetailComponent {
  user: UserInterface | undefined;
  articles: Array<ArticleInterface> | undefined;
  titlePath: string | null = null;
  page: number = 0;
  protected readonly AdPositionName = AdPositionName;
  private readonly isBrowser: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private entityMeta: EntityMetaService,
    private drupal7: Drupal7Service,
    private drupal7mapping: Drupal7mappingService,
    private adServe: AdServeServiceService,
    private sssp: SsspService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.loadArticles();
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this.sssp.setPageViewId();

      this.adServe.serveAds([
        AdPositionName.leaderboard,
        AdPositionName.square,
        AdPositionName.stickySquare,
        AdPositionName.footerboard,
      ]);
    }
  }

  loadArticles(offset: number = 0) {
    this.route.paramMap.pipe(
      tap(params => {
        this.titlePath = params.get('titlepath');
        this.drupal7.getUser(this.titlePath).pipe(
          tap((data: UserResult) => {
            if (data && data.uid) {
              this.user = this.drupal7mapping.mapUser(data);
              this.articles = [];
              this.entityMeta.setMeta(new User(this.user));
            }
            else {
              this.router.navigate(['404']);
            }
          })
        ).subscribe(params => {})
      })
    ).subscribe(params => {});
  }
}
