import {AfterContentInit, AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Article, ArticleInterface, Node, Page, SectionInterface} from "../drupal/entities";
import {Drupal7Service} from "../drupal/drupal7.service";
import {Drupal7mappingService} from "../drupal/drupal7mapping.service";
import {ArticleResult, TaxonomyTermResult} from "../drupal/resultTypes";
import {data} from "autoprefixer";
import {tap} from "rxjs";
import {AdPositionName} from "../ads/ad-position/ad-position-map";
import {AdServeServiceService} from "../ads/ad-serve/ad-serve-service.service";
import {isPlatformBrowser} from "@angular/common";
import {EntityMetaService} from "../drupal/entity-meta.service";
import {SsspService} from "../seznam/sssp.service";

@Component({
  selector: 'app-frontpage',
  templateUrl: './frontpage.component.html',
  styleUrls: ['./frontpage.component.css']
})
export class FrontpageComponent implements OnInit, AfterContentInit, AfterViewInit {
  topArticles: Array<ArticleInterface> | undefined;
  teaserArticles: Array<ArticleInterface> | undefined;
  sections: Array<SectionInterface> | undefined;
  sectionArticles: Array<any>;

  private readonly isBrowser: boolean;

  constructor(
    private drupal7: Drupal7Service,
    private drupal7mapping: Drupal7mappingService,
    private adServe: AdServeServiceService,
    private entityMeta: EntityMetaService,
    private sssp: SsspService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.sectionArticles = [];
    this.isBrowser = isPlatformBrowser(platformId);

    this.loadContent();
  }

  ngOnInit(): void {
    if (!this.isBrowser) {
      const frontpage: Page = new Page({
        id: 0,
        title: '[Ctidoma.cz] - [Politika, zajímavosti, osobnosti, zdraví, životní styl, názory, kultura]',
        path: '',
        fullUrl: 'https://www.ctidoma.cz'
      });
      this.entityMeta.setMeta(frontpage);
    }
  }

  ngAfterContentInit() {

  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this.sssp.setPageViewId();

      this.adServe.serveAds([
        AdPositionName.leaderboard,
        AdPositionName.square,
        AdPositionName.stickyRectangle,
        AdPositionName.footerboard,
        AdPositionName.smrMobilClanek,
        AdPositionName.smrMobilClanek2,
      ]);
    }
  }

  private extractArticleIds(articles: Array<ArticleInterface> | undefined): Array<any> {
    const ids = Array();
    articles?.forEach(article => {
      ids.push(article.id);
    });
    return ids;
  }

  private loadContent() {
    this.drupal7.getViewArticles({viewName: 'frontpage_top_news', displayId: 'services_1', limit: 7}).pipe(
      tap((data: Array<ArticleResult>) => {
        this.topArticles = this.drupal7mapping.mapArticles(data);
      })
    ).subscribe((data: Array<ArticleResult>) => {
      this.drupal7.getViewArticles({viewName: 'frontpage_news_image', displayId: 'services_1', args: [this.extractArticleIds(this.topArticles)]}).subscribe((data: Array<ArticleResult>) => {
        this.teaserArticles = this.drupal7mapping.mapArticles(data);
      });
    });

    this.drupal7.getViewTaxonomyTerms({viewName: 'hp_rubriky', displayId: 'services_1'}).pipe(
      tap((data: Array<TaxonomyTermResult>) => {
        this.sections = this.drupal7mapping.mapSections(data);
      })
    ).subscribe((data: Array<TaxonomyTermResult>) => {
      this.sectionArticles = [];
      this.sections?.forEach((value, key) => {
        this.drupal7.getViewArticles({viewName: 'taxonomy_term_rubrika', displayId: 'services_1', args: [value.id], limit: 3}).subscribe((data: Array<ArticleResult>) => {
          this.sectionArticles[key] = this.drupal7mapping.mapArticles(data);
        });
      })
    })
  }

  protected readonly AdPositionName = AdPositionName;
}
