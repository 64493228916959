import {AfterViewInit} from "@angular/core";
import {AdServePosition} from "../ad-serve/ad-serve-service.service";
import {AdPositionName} from "../ad-position/ad-position-map";

export enum AdProviderName {
  sklik = 'sklik',
  adsense = 'adsense',
  ssp = 'ssp',
  admaster = 'admaster',
  cpex = 'cpex',
}

export enum AdDisplayType {
  mobile = 'mobile',
  desktop = 'desktop',
}

export interface AdProviderInterface {
  serveAds(positions: Array<AdPositionName>): Array<AdPositionName>;
  servePopup(): void;
  serveRecommendations(): void;
}
