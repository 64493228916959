import {Injectable} from '@angular/core';
import {AdProviderInterface, AdProviderName} from "../ad-provider/ad-provider";
import {SklikComponent} from "../ad-provider/sklik/sklik.component";
import {SspComponent} from "../ad-provider/ssp/ssp.component";
import {AdPositionName} from "../ad-position/ad-position-map";
import {environment} from "../../../environments/environment.prod";
import {AdsenseComponent} from "../ad-provider/adsense/adsense.component";

export interface AdServePosition {
  providerName: AdProviderName,
  positionId: string,
  width: number,
  height: number,
}

@Injectable({
  providedIn: 'root'
})
export class AdServeServiceService {
  ctidomaRandomNumber: number | undefined; // 1 = sklik, 2 = adsense, 3 = admaster, 4 = cpex
  currentProviderName: AdProviderName | undefined;
  currentProvider: AdProviderInterface | undefined;
  sssp: any;
  servingFinished: boolean = false;
  servedPositions: Array<AdPositionName> = [];

  initAds(isBrowser: boolean) {
    this.servingFinished = false;
    this.servedPositions = [];
    this.sssp = isBrowser ? (window as any).sssp : undefined;
    this.sssp?.config({ webId: 28574 });
    this.sssp?.config({ site: environment.baseUrl });
    if (this.sssp?.displaySeznamAds()) {
      this.sssp?.config({ source: "hp_feed" });
      this.currentProviderName = AdProviderName.ssp;
      this.currentProvider = new SspComponent(isBrowser);
    }
    else {
      this.ctidomaRandomNumber = 1; //Math.round(Math.random() * (2 - 1) + 1); // value of first number is maximum
      switch (this.ctidomaRandomNumber) {
        case 1:
          this.currentProviderName = AdProviderName.sklik;
          this.currentProvider = new SklikComponent(isBrowser);
          break;
        case 2:
          this.currentProviderName = AdProviderName.adsense;
          this.currentProvider = new AdsenseComponent(isBrowser);
          break;
        case 3:
          this.currentProviderName = AdProviderName.ssp;
          this.currentProvider = new SspComponent(isBrowser);
          break;
        case 4:
          this.currentProviderName = AdProviderName.admaster;
          break;
        case 5:
          this.currentProviderName = AdProviderName.cpex;
          break;
      }
    }
  }

  serveAds(adPositions: Array<AdPositionName>): Array<AdPositionName> {
    if (!this.servingFinished && this.currentProvider) {
      this.servedPositions.push(...this.currentProvider.serveAds(adPositions));
      if (this.servedPositions.length >= adPositions.length) {
        this.servingFinished = true;
        this.currentProvider.servePopup();
        this.currentProvider.serveRecommendations();
      }
      else {
        let remainingPositions = adPositions.filter(n => !this.servedPositions.includes(n));
        setTimeout(() => {
          this.serveAds(remainingPositions)
        }, 1000);
      }
    }

    return this.servedPositions;
  }
}
